$primary: #00a7ef;
$text: #333333;
$input: #757575;
$input-line: #dfe2e4;
$background: #f1f5f7;
$line: #d8d8d8;
$checkbox-border: #5a5a5a;
$password-strength: #7d7a77;
$moderate-password: #f7b500;
$strong-password: #f2f54e;
$complete-password: #62dd51;
$warn: #fb1d09;
$text2: #343434;
$inactive-icon: #757575;
$step: #4b4b4b;
$hover: #e7e8e9;
$subtitle: #4a4a4a;
$inactive: #dddddd;
$dash: #979797;
$dash-text: #c1c1c1;
$placeholder: #9b9b9b;
$owner: #f3f5f7;
$key: #7e7e7e;
$value: #424242;
$devices-title: #e02020;
