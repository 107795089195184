@import "./colors.scss";
@import "../scss/breakpoints.scss";

button.app__button {
  height: 50px !important;
  text-transform: uppercase !important;
  font-size: 20px !important;
  font-weight: bold !important;
  color: white !important;
  display: block;
  width: 100%;

  &_white {
    color: $primary !important;
  }
  &_huge {
    height: 80px !important;
  }
  &_mobile {
    border-radius: 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: white;
    width: auto;
    padding: 0 50px;
  }
}
.app__link {
  font-size: 16px !important;
  font-weight: bold !important;
  color: $primary !important;
  padding: 0 !important;
  line-height: normal !important;
  width: fit-content !important;
}
.app__link_huge {
  font-size: 18px;
  font-weight: bold;
  color: $primary;
  text-transform: uppercase;
}
.app__step-title {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: $text;
}
.app__step-subtitle {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: $subtitle;
  margin-top: 40px;
}
.app__controls {
  display: flex;
  margin-bottom: 17px;

  &>* {
    flex: 1;
    margin-left: 50px;

    &:first-child {
      margin-left: 0 !important;
    }
  }
  mat-form-field {
    .app__controls-hint {
      position: absolute;
      top: 5px;
      right: -50px;
      z-index: 1003;
    }
  }
}
.app__section {
  padding-top: 20px;

  &-title {
    font-size: 18px;
    font-weight: bold;
    color: $text;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &-mandatory {
    font-size: 14px;
    text-align: right;
    color: $placeholder;
  }
}
.app__resume {
  &-title {
    font-size: 18px;
    font-weight: bold;
    color: $text;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  &-row {
    font-size: 18px;
    color: black;
    display: flex;
    margin-bottom: 20px;

    &-key {
      color: $placeholder;
      margin-right: 5px;
    }
    &-item {
      flex: 1;
      display: flex;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.app__result {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  align-items: center;
  padding-top: 50px;

  &-logo {
    width: 205px;
    height: 205px;
  }
  &-title {
    margin-top: 50px;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    color: $text;
  }
  &-subtitle {
    font-size: 22px;
    color: $placeholder;
    margin-top: 21px;
    padding: 0 65px;
    text-align: center;
  }
  &-actions {
    margin-top: 50px;
    width: 434px;
  }
}

.rs-guide {
  display: flex;
  flex-direction: column;
  padding: 20px;

  &__header {
    flex-basis: 37%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &-title {
      font-size: 16px;
      font-weight: 500;
      color: $text;
      margin-bottom: 10px;
    }
  }
  &__footer {
    flex-basis: 73%;
  }
  &__number{
    font-size: 84px;
    font-weight: 900;
    color: $devices-title;
    margin-bottom: 20px;
  }
  &__title {
    font-size: 18px;
    color: $text;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &__subtitle {
    font-size: 18px;
    color: $text;
  }
}

@media(max-width: $laptop) {
  .app__resume {
    &-row {
      margin-bottom: 0px;
      flex-direction: column;

      &-item {
        justify-content: space-between;
        margin-bottom: 6px;
        border-bottom: 1px solid gray;
        padding-bottom: 2px;

        &_no-mobile-border {
          border-bottom: none;
        }
      }
    }
  }
}

@media(max-width: $tablet) {
  .app__step-title {
    font-size: 30px;
  }
  .app__controls {
    flex-wrap: wrap;
    margin-bottom: 0px;

    mat-form-field, rs-percent-control {
      width: 100%;
      flex-basis: 100%;

      &:last-child {
        margin-left: 0;
      }
    }
  }
}

@media(max-width: $mobile) {
  button.app__button {
    height: 40px !important;
    font-size: 15px !important;
  }
  .app__step-title {
    font-size: 25px;
  }
  .app__section-title {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .app__controls {
    mat-form-field {
      .app__controls-hint {
        top: 5px;
        right: 0px;
      }
    }
  }
  .app__result {
    width: 280px;
    padding-top: 40px;

    &-logo {
      width: 120px;
      height: 120px;
    }
    &-title {
      margin-top: 30px;
      font-size: 25px;
      padding: 0;
    }
    &-subtitle {
      font-size: 16px;
      margin-top: 10px;
      padding: 0;
    }
    &-actions {
      margin-top: 30px;
      width: 100%;
    }
  }
}
