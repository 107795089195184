@import "./modules/shared/scss/lib-overrides.scss";
@import "./modules/shared/scss/app.scss";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

* {
  box-sizing: border-box;
  outline: none !important;
  line-height: normal;
}
img {
  width: 100%;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
