@import "../scss/colors.scss";
@import "../scss/breakpoints.scss";

.mat-form-field {
  width: 100%;
}

.mat-form-field-appearance-outline {
  .mat-form-field-label, .mat-form-field-infix input {
    font-size: 18px !important;
    color: $text !important;
  }
  .mat-form-field-label {
    font-size: 18px !important;
    color: $placeholder !important;
    transform: translateY(-6px) !important;
  }
  .mat-form-field-outline {
    height: 60px !important;
  }
  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.69em) scale(0.75) !important;
  }
}

.mat-form-field-label {
  font-size: 18px !important;
  color: $placeholder !important;
}
.mat-form-field-infix input {
  color: $text !important;
  font-size: 18px !important;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: $input-line;
  height: 1.8px !important;
}
.mat-form-field .mat-form-field-ripple, .mat-form-field .mat-form-field-ripple.mat-accent {
  height: 1.8px !important;
}

.mat-checkbox-inner-container {
  width: 30px !important;
  height: 30px !important;
}
.mat-checkbox-frame {
  border-width: 1.5px !important;
  border-color: $checkbox-border !important;
  border-radius: 8px !important;
}
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: transparent !important;
  background-image: url("/assets/img/agree.svg") !important;
  svg {
    display: none !important;
  }
}
.mat-checkbox-label {
  font-size: 16px;
  color: $text;
  line-height: 30px !important;
  margin-left: 10px;
}

.rs-phone-control {
  padding-top: 4px !important;

  .iti {
    width: 100%;

    input {
      height: 60px !important;
      width: 100%;
      margin-right: 0 !important;
      border-radius: 4px;
      border: 1px solid $input-line !important;
      font-size: 18px;
      outline: none;
      padding-left: 50px;
      color: $text;
      transition: .3s;

      &:hover {
        border-width: 2.22px !important;
        border-color: $text !important;
      }
      &:focus {
        border-width: 2.22px !important;
        border-color: $primary !important;
      }
      &.ng-invalid.ng-touched {
        border-width: 2.22px !important;
        border-color: $warn !important;
      }
    }
    &__flag-container {
      height: 60px !important;
    }
    &__selected-flag {
      outline: none;
    }
  }

  &_linear {
    padding-top: 0 !important;

    .iti {
      input {
        height: 50px !important;
        border: none !important;
        border-radius: 0px !important;
        border-bottom: 2.2px solid $input-line !important;
        margin-bottom: 17px !important;

        &:hover {
          border-color: $input-line !important;
        }
        &:focus {
          border-width: 2.22px !important;
          border-color: $primary !important;
        }
      }
      &__flag-container {
        height: 50px !important;
      }
    }
  }
}

.mat-form-field-infix {
  height: 51px !important;
}
.mat-select-value-text {
  font-size: 18px !important;
  line-height: 1.56 !important;
}
.mat-select-arrow {
  border: none !important;
  background-image: url("/assets/img/arrow.png") !important;
  height: 30px !important;
  width: 32px !important;
  background-position: 0 0;
  background-repeat: no-repeat;
  margin-top: 14px !important;
  background-size: 50%;
  margin-right: -3px !important;
}

.mat-tab-label-content {
  font-size: 24px !important;
  font-weight: bold !important;
  color: $inactive !important;
}
.mat-ink-bar {
  height: 4px !important;
}
.mat-tab-label {
  height: 70px !important;
}
.mat-tab-label-active {
  .mat-tab-label-content {
    color: $primary !important;
  }
}
.mat-tab-header {
  border: none;
}

.simple-notification-wrapper {
  z-index: 1003 !important;
}

.mat-expansion-panel-header {
  height: 50px !important;
  background-color: $background;
  font-size: 20px !important;
  font-weight: bold !important;
  border-radius: 6px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;


  .mat-expansion-panel-header-title {
    color: $password-strength !important;
    text-transform: uppercase;
  }
  .mat-expansion-indicator::after {
    border-style: none;
  }
  .mat-expansion-indicator {
    background-image: url('../../../assets/img/arrow.png');
    width: 32px !important;
    height: 20px !important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.mat-expansion-panel-header.mat-expanded:focus, .mat-expansion-panel-header.mat-expanded:hover {
  background-color: $background !important;
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}
.mat-expansion-panel-content {
  border-radius: 6px;
  border: solid 1px $input-line;
  background-color: white;
}
.mat-expansion-panel-body {
  padding: 0 !important;
}

.carousel {
  height: 100% !important;

  &-slide-overlay {
    background-color: transparent !important;
  }
  &-list {
    height: 100% !important;
  }
  &-slide {
    height: 100% !important;
  }
  &-indicators {
    width: 100% !important;
    margin: 0 !important;
    bottom: 45px !important;

    &>button {
      width: 12px !important;
      height: 12px !important;
      background-color: $devices-title !important;
      box-shadow: none !important;
      transition: .5s;
      border-radius: 12px !important;

      &.mat-button-disabled {
        width: 27px !important;
      }
    }
  }
}

@media(max-width: $mobile) {
  .mat-checkbox-label {
    font-size: 11px;
    margin-left: 2px;
  }
  .mat-tab-label-content {
    font-size: 18px !important;
  }
  .mat-tab-label {
    height: 40px !important;
  }
  .mat-form-field-empty.mat-form-field-label, .mat-form-field-appearance-outline .mat-form-field-infix input {
    padding-right: 37px;
  }

  .mat-expansion-panel-header {
    font-size: 14px !important;

    .mat-expansion-indicator {
      width: 20px !important;
      height: 11px !important;
    }
  }
}
